<template>
  <div id="compare-page">
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Compare
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- Start compare Wrapper -->
    <div class="comparison-wrapper pb-50">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <main id="primary" class="site-main">
              <div class="comparison">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="section-title">
                      <h3>your Compared Deals</h3>
                    </div>
                    <form action="#">
                      <div class="table-responsive text-center">
                        <table class="table table-bordered compare-style">
                          <thead>
                            <tr>
                              <td colspan="4">
                                <strong>Deals Details</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="product-title">Deal Name</td>
                              <td>
                                <a>
                                  <strong v-if="$cookies.get('pro1') != null">
                                    {{ singleOffer1.title }}
                                  </strong>
                                </a>
                              </td>
                              <td>
                                <a>
                                  <strong v-if="$cookies.get('pro2') != null">
                                    {{ singleOffer2.title }}
                                  </strong>
                                </a>
                              </td>
                              <td>
                                <a>
                                  <strong v-if="$cookies.get('pro3') != null">
                                    {{ singleOffer3.title }}
                                  </strong>
                                </a>
                              </td>
                            </tr>
                            <tr class="img_compare">
                              <td class="product-title">Image</td>
                              <td>
                                <img
                                  style="width: 8rem;"
                                  class="d-block m-auto"
                                  :src="
                                    publicPath +
                                      singleOffer1.img
                                  "
                                  alt
                                />
                              </td>
                              <td>
                                <img
                                  style="width: 8rem;"
                                  v-if="$cookies.get('pro1') != null"
                                  class="d-block m-auto"
                                  :src="
                                    publicPath +
                                      singleOffer2.img
                                  "
                                  alt
                                />
                              </td>
                              <td>
                                <img
                                  style="width: 8rem;"
                                  v-if="$cookies.get('pro3') != null"
                                  class="d-block m-auto"
                                  :src="
                                    publicPath +
                                      singleOffer3.img
                                  "
                                  alt
                                />
                              </td>
                            </tr>

                            <tr>
                              <td class="product-title">Brand</td>
                              <td>
                                <a
                                  href="#"
                                  class="text-color"
                                  v-if="$cookies.get('pro1') != null"
                                >
                                  <img
                                    class="d-block m-auto"
                                    style="width: 4rem;"
                                    :src="
                                      publicPath +
                                        singleOffer1.product.brand.img
                                    "
                                    alt
                                  />
                                </a>
                              </td>
                              <td>
                                <a
                                  href="#"
                                  class="text-color"
                                  v-if="$cookies.get('pro2') != null"
                                >
                                  <img
                                    class="d-block m-auto"
                                    style="width: 4rem;"
                                    :src="
                                      publicPath +
                                        singleOffer2.product.brand.img
                                    "
                                    alt
                                  />
                                </a>
                              </td>
                              <td>
                                <a
                                  href="#"
                                  class="text-color"
                                  v-if="$cookies.get('pro3') != null"
                                >
                                  <img
                                    class="d-block m-auto"
                                    style="width: 4rem;"
                                    :src="
                                      publicPath +
                                        singleOffer3.product.brand.img
                                    "
                                    alt
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Network</td>
                              <td>
                                <router-link
                                  class="text-color"
                                  :to="
                                    '/networks/' +
                                      singleOffer1.network.slug +
                                      '?network=' +
                                      singleOffer1.network.slug
                                  "
                                  v-if="$cookies.get('pro1') != null"
                                >
                                  <img
                                    class="d-block m-auto"
                                    style="width: 4rem;"
                                    :src="
                                      publicPath +
                                        singleOffer1.network.img
                                    "
                                    alt
                                  />
                                </router-link>
                              </td>
                              <td>
                                <router-link
                                  class="text-color"
                                  :to="
                                    '/networks/' +
                                      singleOffer2.network.slug +
                                      '?network=' +
                                      singleOffer2.network.slug
                                  "
                                  v-if="$cookies.get('pro2') != null"
                                >
                                  <img
                                    class="d-block m-auto"
                                    style="width: 4rem;"
                                    :src="
                                      publicPath +
                                        singleOffer2.network.img
                                    "
                                    alt
                                  />
                                </router-link>
                              </td>
                              <td>
                                <router-link
                                  class="text-color"
                                  :to="
                                    '/networks/' +
                                      singleOffer3.network.slug +
                                      '?network=' +
                                      singleOffer3.network.slug
                                  "
                                  v-if="$cookies.get('pro3') != null"
                                >
                                  <img
                                    class="d-block m-auto"
                                    style="width: 4rem;"
                                    :src="
                                      publicPath +
                                        singleOffer3.network.img
                                    "
                                    alt
                                  />
                                </router-link>
                              </td>
                            </tr>
                            <tr class="color_compare">
                              <td class="product-title">Colour</td>
                              <td class="description">
                                <div
                                  v-if="$cookies.get('pro1') != null"
                                  class="color-optionn"
                                >
                                  <ul>
                                    <li>
                                      <span> {{ singleOffer1.colors.title }} </span>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                              <td class="description">
                                <div
                                  v-if="$cookies.get('pro2') != null"
                                  class="color-optionn"
                                >
                                  <ul>
                                    <li>
                                      <span> {{ singleOffer2.colors.title }} </span>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                              <td class="description">
                                <div
                                  v-if="$cookies.get('pro3') != null"
                                  class="color-optionn "
                                >
                                  <ul>
                                    <li>
                                      <span> {{ singleOffer3.colors.title }} </span>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Storage</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  {{ singleOffer1.storagge.title }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  {{ singleOffer2.storagge.title }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  {{ singleOffer3.storagge.title }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Coverage</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  {{ singleOffer1.simNetwork }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  {{ singleOffer2.simNetwork }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  {{ singleOffer3.simNetwork }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Minutes</td>
                              <td>
                                <span
                                  v-if="
                                    $cookies.get('pro1') != null &&
                                      singleOffer1.minutes !== '987654321'
                                  "
                                >
                                  <span v-if="singleOffer1.minutes == 123456789"
                                    >Unlimited</span
                                  >
                                  <span v-else>{{ singleOffer1.minutes }}</span>
                                </span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    $cookies.get('pro2') != null &&
                                      singleOffer2.minutes !== '987654321'
                                  "
                                >
                                  <span v-if="singleOffer2.minutes == 123456789"
                                    >Unlimited</span
                                  >
                                  <span v-else>{{ singleOffer2.minutes }}</span>
                                </span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    $cookies.get('pro3') != null &&
                                      singleOffer3.minutes !== '987654321'
                                  "
                                >
                                  <span v-if="singleOffer3.minutes == 123456789"
                                    >Unlimited</span
                                  >
                                  <span v-else>{{ singleOffer3.minutes }}</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Text</td>
                              <td>
                                <span
                                  v-if="
                                    $cookies.get('pro1') != null &&
                                      singleOffer1.text !== '987654321'
                                  "
                                >
                                  <span v-if="singleOffer1.text == 123456789"
                                    >Unlimited</span
                                  >
                                  <span v-else>{{ singleOffer1.text }}</span>
                                </span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    $cookies.get('pro2') != null &&
                                      singleOffer2.text !== '987654321'
                                  "
                                >
                                  <span v-if="singleOffer2.text == 123456789"
                                    >Unlimited</span
                                  >
                                  <span v-else>{{ singleOffer2.text }}</span>
                                </span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    $cookies.get('pro3') != null &&
                                      singleOffer3.text !== '987654321'
                                  "
                                >
                                  <span v-if="singleOffer3.text == 123456789"
                                    >Unlimited</span
                                  >
                                  <span v-else>{{ singleOffer3.text }}</span>
                                </span>
                              </td>
                            </tr>
                            <tr
                              v-if="
                                singleOffer1.speed &&
                                  singleOffer2.speed &&
                                  singleOffer3.speed
                              "
                            >
                              <td class="product-title">Speed</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  {{ singleOffer1.speed }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  {{ singleOffer2.speed }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  {{ singleOffer3.speed }}
                                </span>
                              </td>
                            </tr>
                            <tr
                              v-if="
                                singleOffer1.download &&
                                  singleOffer2.download &&
                                  singleOffer3.download
                              "
                            >
                              <td class="product-title">Download</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  <a
                                    v-if="singleOffer1.download === '123456789'"
                                    >Unlimited
                                  </a>
                                  <a v-else> {{ singleOffer1.download }}GB </a>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  <a
                                    v-if="singleOffer2.download === '123456789'"
                                    >Unlimited
                                  </a>
                                  <a v-else> {{ singleOffer2.download }}GB</a>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  <a
                                    v-if="singleOffer3.download === '123456789'"
                                    >Unlimited
                                  </a>
                                  <a v-else> {{ singleOffer3.download }}GB</a>
                                </span>
                              </td>
                            </tr>
                            <tr
                              v-if="
                                singleOffer1.channel &&
                                  singleOffer2.channel &&
                                  singleOffer3.channel
                              "
                            >
                              <td class="product-title">Channel</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  {{ singleOffer1.channel }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  {{ singleOffer2.channel }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  {{ singleOffer3.channel }}
                                </span>
                              </td>
                            </tr>
                            <tr
                              v-if="
                                singleOffer1.data &&
                                  singleOffer2.data &&
                                  singleOffer3.data
                              "
                            >
                              <td class="product-title">Data</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  <a v-if="singleOffer1.data === '123456789'"
                                    >Unlimited Data
                                  </a>
                                  <a v-else-if="singleOffer1.data >= 1000"
                                    >{{ singleOffer1.data / 1000 }}GB Of
                                    {{ singleOffer1.simNetwork }} Data</a
                                  >
                                  <a v-else> {{ singleOffer1.data }}MB Data</a>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  <a v-if="singleOffer2.data === '123456789'"
                                    >Unlimited Data
                                  </a>
                                  <a v-else-if="singleOffer2.data >= 1000"
                                    >{{ singleOffer2.data / 1000 }}GB Of
                                    {{ singleOffer2.simNetwork }} Data</a
                                  >
                                  <a v-else> {{ singleOffer2.data }}MB Data</a>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  <a v-if="singleOffer3.data === '123456789'"
                                    >Unlimited Data
                                  </a>
                                  <a v-else-if="singleOffer3.data >= 1000"
                                    >{{ singleOffer3.data / 1000 }}GB Of
                                    {{ singleOffer3.simNetwork }} Data</a
                                  >
                                  <a v-else> {{ singleOffer3.data }}MB Data</a>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">
                                Upfront Cost
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  <span v-if="singleOffer1.upfront_cost === '1'"
                                    >£0
                                  </span>
                                  <span v-else>
                                    £{{ singleOffer1.upfront_cost }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  <span v-if="singleOffer2.upfront_cost === '1'"
                                    >£0
                                  </span>
                                  <span v-else>
                                    £{{ singleOffer2.upfront_cost }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  <span v-if="singleOffer3.upfront_cost === '1'"
                                    >£0
                                  </span>
                                  <span v-else>
                                    £{{ singleOffer3.upfront_cost }}
                                  </span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Contract Length</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  {{ singleOffer1.contract }} Monthes
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  {{ singleOffer2.contract }} Monthes
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  {{ singleOffer3.contract }} Monthes
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Monthly Cost</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  £{{ singleOffer1.monthly_cost }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  £{{ singleOffer2.monthly_cost }}
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  £{{ singleOffer3.monthly_cost }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Total Cost</td>
                              <td>
                                <span v-if="$cookies.get('pro1') != null">
                                  <span v-if="singleOffer1.upfront_cost == 1">
                                    £{{
                                      parseFloat(
                                        singleOffer1.monthly_cost *
                                          singleOffer1.contract
                                      )
                                    }}
                                  </span>
                                  <span v-else>
                                    £{{
                                      parseFloat(
                                        singleOffer1.monthly_cost *
                                          singleOffer1.contract
                                      ) + parseFloat(singleOffer1.upfront_cost)
                                    }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro2') != null">
                                  <span v-if="singleOffer2.upfront_cost == 1">
                                    £{{
                                      parseFloat(
                                        singleOffer2.monthly_cost *
                                          singleOffer2.contract
                                      )
                                    }}
                                  </span>
                                  <span v-else>
                                    £{{
                                      parseFloat(
                                        singleOffer2.monthly_cost *
                                          singleOffer2.contract
                                      ) + parseFloat(singleOffer2.upfront_cost)
                                    }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="$cookies.get('pro3') != null">
                                  <span v-if="singleOffer3.upfront_cost == 1">
                                    £{{
                                      parseFloat(
                                        singleOffer3.monthly_cost *
                                          singleOffer3.contract
                                      )
                                    }}
                                  </span>
                                  <span v-else>
                                    £{{
                                      parseFloat(
                                        singleOffer3.monthly_cost *
                                          singleOffer3.contract
                                      ) + parseFloat(singleOffer3.upfront_cost)
                                    }}
                                  </span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td class="product-title">Actions</td>
                              <td>
                                <a
                                  v-if="$cookies.get('pro1') != null"
                                  :href="singleOffer1.link"
                                  class="btn btn-outline-info mb-2 mb-lg-0 mr-xl-2"
                                  >Buy Now</a
                                >
                                <a
                                  v-if="$cookies.get('pro1') != null"
                                  @click="$cookies.remove('pro1'), reload()"
                                  class="btn btn-danger"
                                  >Remove</a
                                >
                              </td>
                              <td>
                                <a
                                  v-if="$cookies.get('pro2') != null"
                                  :href="singleOffer2.link"
                                  class="btn btn-outline-info mb-2 mb-lg-0 mr-xl-2"
                                  >Buy Now</a
                                >
                                <a
                                  v-if="$cookies.get('pro2') != null"
                                  @click="$cookies.remove('pro2'), reload()"
                                  class="btn btn-danger"
                                  >Remove</a
                                >
                              </td>
                              <td>
                                <a
                                  v-if="$cookies.get('pro3') != null"
                                  :href="singleOffer3.link"
                                  class="btn btn-outline-info mb-2 mb-lg-0 mr-xl-2"
                                  >Buy Now</a
                                >
                                <a
                                  v-if="$cookies.get('pro3') != null"
                                  @click="$cookies.remove('pro3'), reload()"
                                  class="btn btn-danger"
                                  >Remove</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- end of comparison -->
            </main>
            <!-- end of #primary -->
          </div>
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </div>
    <!-- End compare Wrapper -->
  </div>
</template>

<script>
export default {
  name: "HeaderComp",
  components: {},
  data() {
    return {
      singleOffer1: [],
      singleOffer2: [],
      singleOffer3: [],
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getSingleOffer1();
    this.getSingleOffer2();
    this.getSingleOffer3();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getSingleOffer1();
      this.getSingleOffer2();
      this.getSingleOffer3();
    }
  },
  methods: {
    reload() {
      window.location.reload();
    },
    getSingleOffer1() {
      this.loading = true;
      this.$http
        .get("offers/" + window.$cookies.get("pro1"))
        .then(response => {
          this.singleOffer1 = response.data;
          response => (this.singleOffer1 = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getSingleOffer2() {
      this.loading = true;
      this.$http
        .get("offers/" + window.$cookies.get("pro2"))
        .then(response => {
          this.singleOffer2 = response.data;
          response => (this.singleOffer2 = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getSingleOffer3() {
      this.loading = true;
      this.$http
        .get("offers/" + window.$cookies.get("pro3"))
        .then(response => {
          this.singleOffer3 = response.data;
          response => (this.singleOffer3 = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
